// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: constant.BASE_URL_PROD }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.auth}${apiurl.signin}`,
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.auth}${apiurl.forgotPassword}`,
        method: "POST",
        body: credentials,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.auth}${apiurl.verifyOtp}`,
        method: "POST",
        body: credentials,
      }),
    }),
    resendotp: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.auth}${apiurl.resendOtp}`,
        method: "POST",
        body: credentials,
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.auth}${apiurl.resetPassword}`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useVerifyOTPMutation,
  useResendotpMutation,
} = authApi;
