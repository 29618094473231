import React, { forwardRef } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const CustomInput = forwardRef((props, ref) => {
  const {
    field,
    autoFocus = false,
    label = "",
    placeholder = "",
    type = "text",
    required = false,
    minLength = 0,
    maxLength = 255,
    value = "",
    onChange,
    disabled = false,
    className = "",
    as,
  } = props;

  return (
    <FloatingLabel label={label} className="">
      <Form.Control
        {...field}
        as={as}
        type={type}
        autoComplete="off"
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        value={value}
        className={className}
        disabled={disabled}
        onChange={(value) => onChange(value)}
        autoFocus={autoFocus}
      />
    </FloatingLabel>
  );
});

export default CustomInput;
