import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import SignIn from "../components/SignIn";
import DashBoardPage from "../pages/DahsBoard";
const PublicdRoute = () => {
  const navigate = useNavigate();
  const userdetail = useSelector((state) => state.userdetail.userdetail);

  useEffect(() => {
    if (userdetail && userdetail?.token) {
      navigate("/dashboard");
    }
  }, [userdetail, navigate]);
  return (
    <>{userdetail && userdetail?.token ? navigate("/dashboard") : <Outlet />}</>
  );
};

export default PublicdRoute;
