// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const videoApi = createApi({
  reducerPath: "videoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: constant.BASE_URL_PROD,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().userdetail.userdetail?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Video"],
  endpoints: (builder) => ({
    uploadVideo: builder.mutation({
      query: (data) => ({
        url: `${apiurl.tonyadmin}${apiurl.uploadvideo}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Video"],
    }),
    UpdateVideo: builder.mutation({
      query: ({ data, id }) => ({
        url: `${apiurl.tonyadmin}${apiurl.uploadvideo}/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Video"],
    }),
    getVideoContent: builder.query({
      query: ({ params }) => {
        const queryString = new URLSearchParams(params).toString();
        return {
          url: `${apiurl.user}${apiurl.getallvideorecords}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Video"],
    }),
    getVideoContentBycat: builder.query({
      query: ({ cat, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return {
          url: `${apiurl.user}${apiurl.getvideosbycat}/${cat}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Video"],
    }),
    getVideoDetailById: builder.query({
      query: ({ id }) => {
        return {
          url: `${apiurl.user}${apiurl.getvideodetail}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Video"],
    }),
    deleteVideo: builder.mutation({
      query: (id) => ({
        url: `${apiurl.tonyadmin}${apiurl.video}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Video"],
    }),
  }),
});

export const {
  useUploadVideoMutation,
  useGetVideoContentQuery,
  useGetVideoContentBycatQuery,
  useGetVideoDetailByIdQuery,
  useDeleteVideoMutation,
  useUpdateVideoMutation,
} = videoApi;
