import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../../utils/Validations";
import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";
import { GoBackIconOnVideoPage, UploadVideoIcon } from "../../utils/Svg";
import { MdFrontLoader, MdOutlineEmojiEmotions } from "react-icons/md";
import { useSelector } from "react-redux";
import { LoaderCustom } from "../Loader";
import EmojiPicker from "emoji-picker-react";
import Picker from "emoji-picker-react";

const UploadVideo = ({
  pageName,
  btnNAme,
  onDataSubmit,
  goBack = false,
  acceptedFilesFirst,
  acceptedFilesSecond,
  getRootPropsFirst,
  getInputPropsFirst,
  getRootPropsSecond,
  getInputPropsSecond,
  data,
  inputStr,
  setInputStr,
  isLoading,
  uploadvideo,
}) => {
  const pagename = useSelector((state) => state.pagename.pagename);
  //   const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + event?.emoji);
    setEmojiOpen(false);
  };

  const FirstFiles = acceptedFilesFirst?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const SecondFiles = acceptedFilesSecond?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const navigate = useNavigate();
  //   const [file, setFile] = useState(null);
  const fileTypes = [
    "video/mp4",
    "video/mpeg",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-ms-wmv",
  ];

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const Category = [
    { value: "PODCASTING", label: "Podcasting" },
    { value: "VLOGGING", label: "Vlogging" },
    { value: "MUSICVIDEOS", label: "Music Videos" },
    { value: "BITESIZEDCLIPS", label: "Bite Sized Clips" },
    { value: "REALITYSHOW", label: "Reality Show" },
  ];
  const Plans = [
    { value: "BASIC", label: "Basic" },
    { value: "GOLD", label: "Gold" },
    { value: "PRO", label: "Platinum" },
  ];
  const Visibility = [
    { value: "PRIVATE", label: "Public" },
    { value: "PUBLIC", label: "Private" },
  ];

  useEffect(() => {
    setInputStr(data?.data?.title || ""); // Set the titleofthevideo field
    reset({
      category: Category.find((cat) => cat.value === data?.data?.category),
      visibility: Visibility.find(
        (cat) => cat.value === data?.data?.visibility
      ),
      package: Plans.find((cat) => cat.value === data?.data?.plan),
      terms: data?.data?.description,
    });
  }, [data, reset]);

  return (
    <>
      <div className="upload-video">
        <Form onSubmit={handleSubmit(onDataSubmit)} className="upload-form">
          <div className="head d-flex">
            {goBack ? (
              <div className="cursor" onClick={() => navigate(`/${pagename}`)}>
                <GoBackIconOnVideoPage />
              </div>
            ) : (
              ""
            )}

            <div className="page-name">{pageName}</div>
            <div className="btn-submit ms-auto">
              {isLoading ? (
                <div className="loader-with-button">
                  <div className="uploading-text">Uploading Video....</div>{" "}
                  <div className="loader">
                    <LoaderCustom />
                  </div>
                </div>
              ) : (
                <Button type="submit">{btnNAme}</Button>
              )}
            </div>
          </div>
          <div className="inputs mt-4 mb-5">
            <Row>
              <Col xs={12} md={6}>
                <div className="label-custom mt-3">
                  Title of the video (required)
                </div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="titleofthevideo"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.Required,
                      },
                      maxLength: {
                        value: VALIDATIONS.NAME_MAX,
                        message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                    }}
                    render={({ field }) => (
                      <div style={{ position: "relative" }}>
                        <CustomInput
                          {...field}
                          label="Title of the Video"
                          placeholder="Title of the Video"
                          type="text"
                          className="name"
                          required={true}
                          maxLength={VALIDATIONS.NAME_MAX}
                          // value={field.value}
                          // onChange={field.onChange}
                          value={inputStr}
                          // onChange={(e) => {
                          //   field.onChange;
                          //   setInputStr(e.target.value);
                          // }}
                          onChange={(e) => {
                            field.onChange(e.target.value); // Call field.onChange with the new value
                            setInputStr(e.target.value); // Update inputStr
                          }}
                        />
                        {emojiOpen && (
                          <div
                            style={{
                              position: "absolute",
                              top: "50px",
                              right: "10px",
                              zIndex: 1000,
                            }}
                          >
                            <Picker
                              pickerStyle={{ width: "100%" }}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        )}
                        <MdOutlineEmojiEmotions
                          className="cursor"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            color: "white",
                            width: "23px",
                            height: "23px",
                          }}
                          onClick={() => setEmojiOpen(!emojiOpen)}
                        />
                      </div>
                    )}
                  />
                  {errors.titleofthevideo && (
                    <small className="text-start d-block text-danger">
                      {errors.titleofthevideo.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="label-custom mt-3">
                  Select the Category of the video
                </div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="category"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.Required,
                      },
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        label="Select Category"
                        placeholder="Select Category"
                        className="name select"
                        options={Category}
                        required={true}
                        // maxLength={VALIDATIONS.EMAIL}
                        // value={field.value}
                        value={field.value} // Change 4: Ensure the value is passed correctly
                        onChange={field.onChange} // Change 4: Ensure the onChange is passed correctly
                      />
                    )}
                  />
                  {errors.category && (
                    <small className="text-start d-block text-danger">
                      {errors.category.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="label-custom mt-5">
                  Select the Package, Where this video falls
                </div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="package"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.Required,
                      },
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        label="Select Package"
                        placeholder="Select Package"
                        className="name select"
                        options={Plans}
                        required={true}
                        // maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {errors.package && (
                    <small className="text-start d-block text-danger">
                      {errors.package.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="label-custom mt-5">Set the Visibility</div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="visibility"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.Required,
                      },
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        label="Set the Visibility"
                        placeholder="Set the Visibility"
                        className="name select"
                        options={Visibility}
                        required={true}
                        maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {errors.visibility && (
                    <small className="text-start d-block text-danger">
                      {errors.visibility.message}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="label-custom mt-5"> Description of Video</div>
                <div className="edit-terms text-area d-flex">
                  <div className="mt-3">
                    <Controller
                      control={control}
                      name="terms"
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.TEXT_AREA,
                        },
                        maxLength: {
                          value: VALIDATIONS.TextArea,
                          message: VALIDATIONS_TEXT.TEXT_AREA_MAX,
                        },
                      }}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Terms And Condition"
                          placeholder="Terms And Condition"
                          as="textarea"
                          className="text-name"
                          required={true}
                          maxLength={VALIDATIONS.TextArea}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.terms && (
                      <small className="text-start d-block text-danger">
                        {errors.terms.message}
                      </small>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {uploadvideo ? (
            <>
              <div className="line-podcast mt-5"></div>
              <div className="drag-drop-file mt-5">
                <Row>
                  <Col xs={12} md={6}>
                    <div className="label-custom mt-3 mb-3">
                      <p
                        style={{
                          color: "white",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                        }}
                      >
                        Short Content Video
                      </p>
                      <p
                        style={{
                          color: "#7B818C",
                          fontWeight: 700,
                          fontSize: "12px",
                          lineHeight: "19.2px",
                        }}
                      >
                        Upload a short content video, so the viewers get an idea
                        what’s in the video
                      </p>
                    </div>
                    <div className="drag-and-drop d-flex">
                      <div {...getRootPropsFirst({ className: "dropzone" })}>
                        <input {...getInputPropsFirst()} />
                        <div className="cursor custom-drag-content">
                          <UploadVideoIcon />
                          {FirstFiles?.length > 0 ? (
                            <>
                              <h2>uploaded Successfully</h2>
                              <h3>{FirstFiles}</h3>
                            </>
                          ) : (
                            <>
                              <h2>Drag and drop video files to upload</h2>
                              <h3>
                                Your video will be private until you publish
                                them.
                              </h3>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="btn-select"
                        style={{
                          marginRight: "20px",
                        }}
                      >
                        <div
                          {...getRootPropsFirst({ className: "btn-dropzone" })}
                        >
                          <input {...getInputPropsFirst()} />
                          <Button>Select Files</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="label-custom mt-3 mb-3">
                      <p
                        style={{
                          color: "white",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                        }}
                      >
                        Complete Content Video
                      </p>
                      <p
                        style={{
                          color: "#7B818C",
                          fontWeight: 700,
                          fontSize: "12px",
                          lineHeight: "19.2px",
                        }}
                      >
                        Upload a complete content video, so when the viewers
                        subscribe or purchase it
                      </p>
                    </div>

                    <div className="drag-and-drop d-flex">
                      <div {...getRootPropsSecond({ className: "dropzone" })}>
                        <input {...getInputPropsSecond()} />
                        <div className="cursor custom-drag-content">
                          <UploadVideoIcon />
                          {SecondFiles?.length > 0 ? (
                            <>
                              <h2>uploaded Successfully</h2>
                              <h3>{SecondFiles}</h3>
                            </>
                          ) : (
                            <>
                              <h2>Drag and drop video files to upload</h2>
                              <h3>
                                Your video will be private until you publish
                                them.
                              </h3>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="btn-select">
                        <div
                          {...getRootPropsSecond({ className: "btn-dropzone" })}
                        >
                          <input {...getInputPropsSecond()} />
                          <Button>Select Files</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            ""
          )}
        </Form>
      </div>
    </>
  );
};
export default UploadVideo;
