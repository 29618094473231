import React from "react";
import { CChart } from "@coreui/react-chartjs";

const LineChart = () => {
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = Array.from({ length: 100 }, (_, index) => {
    const randomMonth = Math.floor(Math.random() * 12); // Random month index from 0 to 11
    const randomDay = Math.floor(Math.random() * 28) + 1; // Random day from 1 to 28
    const monthName = labels[randomMonth];
    const year = 2020; // or any desired year
    const revenue = Math.floor(Math.random() * (50 - 20 + 1) + 20) * 100; // Random revenue between 20 and 50
    return {
      date: `${randomDay}-${monthName}-${year}`,
      revenue: revenue,
    };
  });
  const sortedData = data.sort((a, b) => {
    const monthOrder = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const monthA = a.date.split("-")[1]; // Extracting the month from the date
    const monthB = b.date.split("-")[1];
    return monthOrder[monthA] - monthOrder[monthB];
  });

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          label: function (context) {
            const label = sortedData[context.dataIndex].date;
            const value = `$${sortedData[
              context.dataIndex
            ].revenue.toLocaleString()}`;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "rgba(151, 187, 205, 1)",
        pointBorderColor: "#fff",
        data: sortedData.map((item) => item.revenue),
      },
    ],
  };

  return (
    <div className="line-chart">
      <h1 className="title">Total Revenue</h1>
      <div className={"line-chart-graph"}>
        <CChart type="line" data={chartData} options={options} />
      </div>
    </div>
  );
};

export default LineChart;
