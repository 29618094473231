import React, { useState } from "react";
import UploadVideo from "../components/VideoDetails/UploadVideo";
import { errorToast, success } from "../components/ToastComponent";
import { useDropzone } from "react-dropzone";
import {
  useGetVideoContentBycatQuery,
  useGetVideoContentQuery,
  useGetVideoDetailByIdQuery,
  useUpdateVideoMutation,
} from "../Redux/Slices/Videos";
import { useParams } from "react-router-dom";

const EditVideoPage = () => {
  const { id } = useParams();
  const [inputStr, setInputStr] = useState("");
  const { data, error, isLoading } = useGetVideoDetailByIdQuery({ id: id });
  const fetchAllContents = useGetVideoContentQuery();
  const fetchAllContentsByCat = useGetVideoContentBycatQuery();
  const [updateVideo, { isLoading: isUpdating }] = useUpdateVideoMutation();
  const {
    acceptedFiles: acceptedFilesFirst,
    getRootProps: getRootPropsFirst,
    getInputProps: getInputPropsFirst,
  } = useDropzone({
    accept: "video/*",
  });

  const {
    acceptedFiles: acceptedFilesSecond,
    getRootProps: getRootPropsSecond,
    getInputProps: getInputPropsSecond,
  } = useDropzone({
    accept: "video/*",
  });
  const onDataSubmit = async (data) => {
    data["title"] = inputStr;
    data["category"] = data?.category?.value;
    data["plan"] = data?.package?.value;
    data["visibility"] = data?.visibility?.value;
    data["description"] = data.terms;
    delete data["titleofthevideo"];
    delete data["package"];
    delete data["terms"];

    try {
      const result = await updateVideo({ data, id }).unwrap();
      console.log("API Response:", result); // Log API response
      success(result.message);
      fetchAllContents.refetch();
      fetchAllContentsByCat.refetch();
    } catch (e) {
      console.error("API Error:", e); // Log API error
      errorToast(e.data?.message);
    }
  };
  return (
    <>
      <div className="edit-video-page upload-video-page mt-5 container">
        <UploadVideo
          goBack={true}
          pageName={"Edit Video Details"}
          btnNAme={"Save Changes"}
          onDataSubmit={onDataSubmit}
          acceptedFilesFirst={acceptedFilesFirst}
          acceptedFilesSecond={acceptedFilesSecond}
          getRootPropsFirst={getRootPropsFirst}
          getInputPropsFirst={getInputPropsFirst}
          getRootPropsSecond={getRootPropsSecond}
          getInputPropsSecond={getInputPropsSecond}
          data={data}
          inputStr={inputStr}
          setInputStr={setInputStr}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
export default EditVideoPage;
