import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
import SignIn from "../components/SignIn";
import ForgotPassword from "../components/ForgotPassword";
import ChangePassword from "../components/ChangePassword";
import DahsBoardMenu from "../Layouts/AuthLayout/DashBoard";
import DashBoardPage from "../pages/DahsBoard";
import SettingPage from "../pages/Setting";
import HelpAndSupport from "../pages/HelpAndSupport";
import Plans from "../pages/Plans";
import Contents from "../pages/Contents";
import Subscribers from "../pages/Subscribers";
import UploadVideo from "../components/VideoDetails/UploadVideo";
import UploadVideoPage from "../pages/UploadVideoPage";
import EditVideoPage from "../pages/EditVideoPage";
import VerifyOtp from "../components/OTP";
import PublicdRoute from "./PublicdRoute";
import ProtectedRoute from "./ProtectedRoute";
import UploadCoverImage from "../components/UploadCoverImage";

const Navigation = () => {
  return (
    <>
      <Routes>
        {/* PUBLIC ROUTES AUTH */}
        <Route element={<PublicdRoute />}>
          <Route
            path="/"
            element={<AuthLayout children={<SignIn />} />}
          ></Route>
          <Route
            path="/forgot-password"
            element={<AuthLayout children={<ForgotPassword />} />}
          ></Route>
          <Route
            path="/verify-Otp"
            element={<AuthLayout children={<VerifyOtp />} />}
          ></Route>
          <Route
            path="/change-password"
            element={<AuthLayout children={<ChangePassword />} />}
          ></Route>
        </Route>

        {/* Private Route */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/dashboard"
            element={<DahsBoardMenu children={<DashBoardPage />} />}
          ></Route>
          <Route
            path="/content"
            element={<DahsBoardMenu children={<Contents />} />}
          ></Route>
          <Route
            path="/subscribers"
            element={<DahsBoardMenu children={<Subscribers />} />}
          ></Route>
          <Route
            path="/setting"
            element={<DahsBoardMenu children={<SettingPage />} />}
          ></Route>
          <Route
            path="/upload-video"
            element={<DahsBoardMenu children={<UploadVideoPage />} />}
          ></Route>
          <Route
            path="/upload-image"
            element={<DahsBoardMenu children={<UploadCoverImage />} />}
          ></Route>
          <Route
            path="/edit-video/:id"
            element={<DahsBoardMenu children={<EditVideoPage />} />}
          ></Route>
          <Route
            path="/help-support"
            element={<DahsBoardMenu children={<HelpAndSupport />} />}
          ></Route>
          <Route path="/plans" element={<Plans />}></Route>
        </Route>
      </Routes>
    </>
  );
};

export default Navigation;
