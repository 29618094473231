import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import { Controller, useForm } from "react-hook-form";
import { ForwardArrow } from "../utils/Svg";

import CustomInput from "../components/CustomInput";
import Assets from "../utils/Assets";
import { errorToast, success } from "../components/ToastComponent";
import {
  useCreatePageMutation,
  useGetPageBySlugQuery,
  useUpdatePageMutation,
} from "../Redux/Slices/pages";
import { GrowLoader } from "../components/Loader";

const HelpAndSupport = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [
    createPage,
    {
      isLoading: iscreatePageLoading,
      isSuccess: iscreatePageSuccess,
      isError: iscreatePageError,
      error: createPageError,
    },
  ] = useCreatePageMutation();
  const [
    updatePage,
    {
      isLoading: isupdatePageLoading,
      isSuccess: isupdatePageSuccess,
      isError: isupdatePageError,
      error: updatePageError,
    },
  ] = useUpdatePageMutation();
  const { data, error, isLoading } = useGetPageBySlugQuery({
    params: {
      slug: "terms_and_conditions",
    },
  });
  console.log(data?.data[0]?.content, "data page slug");
  const {
    handleSubmit: handleSubmitHelp,
    control: controlHelp,
    formState: { errors: helpErrors },
  } = useForm({ mode: "onChange" });

  // For Terms Change Form
  const {
    handleSubmit: handleSubmitTerms,
    watch,
    reset: termReset,
    control: controlTerms,
    formState: { errors: termsErrors },
  } = useForm({ mode: "onChange" });

  const onSubmitData = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (data?.data?.length > 0) {
      termReset({
        content: data?.data[0]?.content,
      });
    }
  }, [data, termReset, showEdit]);

  const onSubmitTermsData = async (formData) => {
    formData["slug"] = "terms_and_conditions";
    formData["heading"] = "Terms and Conditions";
    try {
      let result;
      if (data?.data?.length > 0) {
        let id = data?.data[0]?.id;
        result = await updatePage({ data: formData, id }).unwrap();
      } else {
        result = await createPage(formData).unwrap();
      }
      success(result.message);
      setShowEdit(false);
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  return (
    <>
      <div className="help-and-support container">
        <Row className="row-head mt-4">
          <Col xs={12} md={12}>
            <div className="heading">How Can We Help You?</div>
          </Col>
          <Col xs={12} md={12}>
            <Form onSubmit={handleSubmitHelp(onSubmitData)}>
              <div className="form-email mt-3">
                <div className="email-input">
                  <Controller
                    control={controlHelp}
                    name="email"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL,
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        label="Email Address"
                        placeholder="Email Address"
                        type="email"
                        className="name"
                        required={true}
                        maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {helpErrors.email && (
                    <small className="text-start d-block text-danger">
                      {helpErrors.email.message}
                    </small>
                  )}
                </div>
                <Button type="submit">Submit</Button>
              </div>
            </Form>
          </Col>
        </Row>
        <div className="box-row mt-4">
          <div className="box">
            <div className="box-img">
              <img src={Assets.help1} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Account Settings</p>
          </div>
          <div className="box">
            <div className="box-img">
              <img src={Assets.help2} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Forgot Password</p>
          </div>
          <div className="box">
            <div className="box-img">
              <img src={Assets.help3} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Other Issue</p>
          </div>
        </div>
        {showEdit ? (
          <div className="edit-terms d-flex">
            <Form onSubmit={handleSubmitTerms(onSubmitTermsData)}>
              <div className="mt-3">
                <Controller
                  control={controlTerms}
                  name="content"
                  rules={{
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.TEXT_AREA,
                    },
                    maxLength: {
                      value: VALIDATIONS.TextArea,
                      message: VALIDATIONS_TEXT.TEXT_AREA_MAX,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Terms And Condition"
                      placeholder="Terms And Condition"
                      as="textarea"
                      className="name"
                      required={true}
                      maxLength={VALIDATIONS.TextArea}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {termsErrors.content && (
                  <small className="text-start d-block text-danger">
                    {termsErrors.content.message}
                  </small>
                )}
              </div>
              <Button
                className="btn-terms mt-4 ms-auto cursor"
                onClick={() => setShowEdit(false)}
                style={{ color: "red" }}
              >
                close
              </Button>

              <Button className="btn-terms mt-4 ms-auto cursor" type="submit">
                Done
              </Button>
            </Form>
          </div>
        ) : (
          <div className="paragraph mt-4">
            {/* {data?.data?.length > 0 && <p>{data?.data[0]?.content}</p>} */}
            {isLoading ? (
              <GrowLoader />
            ) : (
              data?.data?.length > 0 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.data[0]?.content.replace(/\n/g, "<br />"),
                  }}
                ></p>
              )
            )}
            <p className="mt-4">Terms & Condition</p>
            <div className="edit cursor" onClick={() => setShowEdit(true)}>
              Edit
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HelpAndSupport;
