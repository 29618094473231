import React, { useState } from "react";
import Table from "../components/Table";
import Assets from "../utils/Assets";
import { TableComment, TableLikeCount, TableView } from "../utils/Svg";
import { useNavigate } from "react-router-dom";
import ThemeModal from "../components/Modals/ThemeModals";
import { errorToast, success } from "../components/ToastComponent";
import { constant } from "../utils/constants";
import {
  useDeleteVideoMutation,
  useGetVideoContentBycatQuery,
} from "../Redux/Slices/Videos";
import { formatDate } from "../utils/Helper";
import { useSelector } from "react-redux";

const Contents = () => {
  const [currentOption, setCurrentOption] = useState("MUSICVIDEOS");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const search = useSelector((state) => state.search.search);

  const { data, error, isLoading } = useGetVideoContentBycatQuery({
    cat: currentOption,
    params: {
      page: 1,
      limit: 20,
      ...(search && { search: search }),
    },
  });
  const [deleteVideo, { isLoading: isDeleting }] = useDeleteVideoMutation();

  const navigate = useNavigate();
  const JSONDATA = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    image: index % 2 === 0 ? Assets.tableFirst : Assets.tableSecond,
    name: "Hip Hop Song | The Crazy Boy",
    published: "22 Sep 2023",
    category: "Reels",
    like: "110k",
    view: "110k",
    comments: "24k",
  }));
  const TableHeader = [
    { id: 1, headerName: "Music Video", headerwidth: "270px" },
    { id: 2, headerName: "Published", headerwidth: "140px" },
    { id: 3, headerName: "Category", headerwidth: "150px" },
    { id: 4, headerName: "Likes", headerwidth: "150px" },
    { id: 5, headerName: "Views", headerwidth: "150px" },
    { id: 5, headerName: "Comments", headerwidth: "150px" },
    { id: 6, headerName: "Action", headerwidth: "150px" },
  ];
  const handleDelete = (data) => {
    console.log(data, "selected data");
    setShowDelete(true);
    setDeleteId(data);
  };
  const DummyTableData = data?.data?.data?.map((dt) => ({
    id: dt?.id,
    musicvideo: (
      <div className="video-content d-flex">
        <img
          src={`${constant.BASE_URL_IMAGE}${dt?.thumbnailImage}`}
          style={{
            width: "90.59px",
            height: "61px",
          }}
        />
        <div className="name">{dt?.videodetail?.title}</div>
      </div>
    ),
    published: formatDate(dt?.createdAt),
    category: dt?.videodetail?.category,
    likes: (
      <div className="like-content d-flex">
        <TableLikeCount />
        <div className="count">{dt?.likeCount}</div>
      </div>
    ),
    views: (
      <div className="like-content d-flex">
        <TableView />
        <div className="count">{dt?.views}</div>
      </div>
    ),
    comments: (
      <div className="like-content d-flex">
        <TableComment />
        <div className="count">{dt?.comments}</div>
      </div>
    ),
    action: (
      <div className="actions d-flex">
        <img
          className="cursor"
          src={Assets.tableEdit}
          onClick={() => navigate(`/edit-video/${dt?.videodetail?.id}`)}
        />
        <img
          className="cursor"
          src={Assets.tableDelete}
          onClick={() => handleDelete(dt)}
        />
      </div>
    ),
  }));

  const onSubmitModal = async (data) => {
    try {
      const result = await deleteVideo(deleteId?.videoDetailId).unwrap();
      setShowDelete(false);
      success(result.message);
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const selectOtipn = [
    { id: "PODCASTING", name: "Podcasting" },
    { id: "VLOGGING", name: "Vlogging" },
    { id: "MUSICVIDEOS", name: "Music Videos" },
    { id: "BITESIZEDCLIPS", name: "Bite Sized Clips" },
    { id: "REALITYSHOW", name: "Reality Show" },
  ];
  return (
    <>
      <div className="dashboard container content-page">
        <div className="select-type">
          <ul>
            {selectOtipn?.map((dt) => (
              <li
                key={dt?.id}
                onClick={() => setCurrentOption(dt?.id)}
                className={`cursor ${currentOption === dt?.id ? "active" : ""}`}
              >
                {dt?.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="line-podcast"></div>

        <Table
          TableHeader={TableHeader}
          TableData={DummyTableData}
          header={false}
          JSONDATA={JSONDATA}
          className={"mt-5"}
          maxheight={"866px"}
          isLoading={isLoading}
        />
      </div>
      <ThemeModal
        setState={setShowDelete}
        initialState={showDelete}
        onSubmitModal={onSubmitModal}
        isDeleting={isDeleting}
        cancelText={"Not Now"}
        deletetext={"Delete"}
        heading={"Sure? You want to Delete the Video?"}
        Text={"Once you deleted the video you will no longer to Recover it."}
      />
    </>
  );
};

export default Contents;
