import React, { useState } from "react";
import Assets from "../utils/Assets";
import {
  ForwardArrow,
  TableComment,
  TableLikeCount,
  TableView,
  TotalLike,
  TotalSubscribers,
  TotalVideos,
} from "../utils/Svg";
import TopDetails from "../components/Dashboard/Details";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import ThemeModal from "../components/Modals/ThemeModals";
import { errorToast, success } from "../components/ToastComponent";
import LineChart from "../components/Dashboard/Chart";
import {
  useDeleteVideoMutation,
  useGetVideoContentQuery,
} from "../Redux/Slices/Videos";
import { constant } from "../utils/constants";
import { formatDate } from "../utils/Helper";

const DashBoardPage = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetVideoContentQuery({
    params: {
      page: 1,
      limit: 3,
    },
  });
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [deleteVideo, { isLoading: isDeleting }] = useDeleteVideoMutation();

  // Example usage:
  // console.log(formatDate("2024-06-05T16:00:20.000Z")); // Output: "05 Jun 2024"

  // Example usage with your data structure:
  // const data = [65, 59, 80, 81, 56, 55, 40];
  // const labels = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  // ];

  const handleDelete = (data) => {
    console.log(data, "selected data");
    setShowDelete(true);
    setDeleteId(data);
  };
  const TableHeader = [
    { id: 1, headerName: "Video", headerwidth: "270px" },
    { id: 2, headerName: "Published", headerwidth: "140px" },
    { id: 3, headerName: "Category", headerwidth: "150px" },
    { id: 4, headerName: "Likes", headerwidth: "150px" },
    { id: 5, headerName: "Views", headerwidth: "150px" },
    { id: 5, headerName: "Comments", headerwidth: "150px" },
    { id: 6, headerName: "Action", headerwidth: "150px" },
  ];

  const DummyTableData = data?.data?.data?.map((dt) => ({
    id: dt?.id,
    video: (
      <div className="video-content d-flex">
        <img
          src={`${constant.BASE_URL_IMAGE}${dt?.thumbnailImage}`}
          style={{
            width: "90.59px",
            height: "61px",
          }}
        />
        <div className="name">{dt?.videodetail?.title}</div>
      </div>
    ),
    published: formatDate(dt?.createdAt),
    category: dt?.videodetail?.category,
    likes: (
      <div className="like-content d-flex">
        <TableLikeCount />
        <div className="count">{dt?.videodetail?.likeCount}</div>
      </div>
    ),
    views: (
      <div className="like-content d-flex">
        <TableView />
        <div className="count">{dt?.videodetail?.views}</div>
      </div>
    ),
    comments: (
      <div className="like-content d-flex">
        <TableComment />
        <div className="count">{dt?.videodetail?.comments}</div>
      </div>
    ),
    action: (
      <div className="actions d-flex">
        <img
          className="cursor"
          src={Assets.tableEdit}
          onClick={() => navigate(`/edit-video/${dt?.videodetail?.id}`)}
        />
        <img
          className="cursor"
          src={Assets.tableDelete}
          onClick={() => handleDelete(dt)}
        />
      </div>
    ),
  }));
  const onSeeAllClick = () => {
    console.log("see all man");
    navigate("/content");
  };
  // const onSubmitModal = (data) => {
  //   console.log(data);
  //   setShowDelete(false);
  //   success("Data Successfully Removed");
  // };
  const onSubmitModal = async (data) => {
    try {
      const result = await deleteVideo(deleteId?.videoDetailId  ).unwrap();
      setShowDelete(false);
      success(result.message);
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  return (
    <>
      <div className="dashboard container">
        <TopDetails />
        <div className="chart mb-5 mt-5">
          <LineChart />
        </div>
        <Table
          TableHeader={TableHeader}
          TableData={DummyTableData}
          header={true}
          // JSONDATA={JSONDATA}
          className={"mt-5"}
          maxheight={"500px"}
          onSeeAllClick={onSeeAllClick}
        />
      </div>
      <ThemeModal
        setState={setShowDelete}
        initialState={showDelete}
        onSubmitModal={onSubmitModal}
        cancelText={"Not Now"}
        deletetext={"Delete"}
        heading={"Sure? You want to Delete the Video?"}
        Text={"Once you deleted the video you will no longer to Recover it."}
      />
    </>
  );
};
export default DashBoardPage;
