import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import SignIn from "../components/SignIn";
const ProtectedRoute = () => {
  const userdetail = useSelector((state) => state.userdetail.userdetail);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userdetail && !userdetail?.token) {
      navigate("/");
    }
  }, [userdetail, navigate]);
  return <>{userdetail && userdetail?.token ? <Outlet /> : navigate("/")}</>;
};

export default ProtectedRoute;
