import React, { useState } from "react";

const SubscribersTable = ({
  TableHeader,
  TableData,
  header,
  className = "",
  maxheight,
  width,
}) => {
  const [currentOption, setCurrentOption] = useState(1);

  return (
    <>
      <div
        className={`${className} table-custom sub-table-custom container`}
        style={{ width: width }}
      >
        <div className="table-header mb-4">
          <div className="column-name d-flex">
            {TableHeader?.map((dt) => (
              <div
                className={`cursor ${
                  currentOption === dt?.id ? "heading-name" : "blur-name"
                }`}
                key={dt?.id}
                style={{ width: dt?.headerwidth }}
                onClick={() => setCurrentOption(dt?.id)}
              >
                {dt?.headerName}
              </div>
            ))}
          </div>
          <div className="line-podcast mt-4 mb-1"></div>
        </div>

        <div
          className="sub-table-content"
          style={{ maxHeight: maxheight, overflowY: "scroll" }}
        >
          {TableData?.map((rowData) => (
            <>
              <div className="data d-flex mb-4" key={rowData.id}>
                <div className="subscriber-detail d-flex">
                  <img src={rowData?.image} />
                  <div className="detail-content mt-2">
                    <div className="name">{rowData?.name}</div>
                    <div className="detail mt-1">{rowData?.content}</div>
                  </div>
                </div>
                <div className="followers-img mt-3">
                  {rowData?.users?.map((dt) => (
                    <img src={dt?.image} alt="" />
                  ))}
                </div>
                <div className="following-detail mt-2">
                  <div className="counts">40</div>
                  <div className="text mt-1">Following Subscribers</div>
                </div>
              </div>
              <div className="line-podcast mt-4 mb-1"></div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubscribersTable;
