import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { FaCloudUploadAlt } from "react-icons/fa";

import { setPageName, setSideNav } from "../../Redux/Slices/dashBoardSlice";
import {
  DashboardIcon,
  HelpIcon,
  LogOutArrow,
  Logo,
  PodcastIcon,
  SettingIcon,
  SubscriberIcon,
} from "../../utils/Svg";
import { success } from "../ToastComponent";
import ThemeModal from "../Modals/ThemeModals";
import { setUserDetail } from "../../Redux/Slices/UserSlice";

const SideNavBar = ({ setIsSidebarVisible }) => {
  const pagename = useSelector((state) => state.pagename.pagename);
  const sidenav = useSelector((state) => state.sidenav.sidenav);
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelection = (name) => {
    dispatch(setPageName(name));
    switch (name) {
      case "dashboard":
        return navigate("/dashboard");
      case "Settings":
        return navigate("/setting");
      case "Help/Support":
        return navigate("/help-support");
      case "Content":
        return navigate("/content");
      case "Subscribers":
        return navigate("/subscribers");
      case "Upload Cover Image":
        return navigate("/upload-image");
      default:
        return navigate("/dashboard");
    }
  };

  const handleLogout = () => {
    navigate("/");
    setPageName("/dashboard");
    success("Successfully Logged Out");
  };
  const onSubmitModal = (data) => {
    setShowDelete(false);
    dispatch(setUserDetail(null)); // Dispatching the setUserEmail action
    success("Successfully Logged Out");
  };
  return (
    <div className={`${sidenav ? "sidebar-custom" : "custom-back"}`}>
      <SideNav expanded={sidenav}>
        <div className="toggle-custom">
          <SideNav.Toggle
            onClick={() => {
              dispatch(setSideNav(!sidenav));
            }}
          ></SideNav.Toggle>
          <div
            className={`logo mb-3 cursor ${sidenav ? "" : "d-none"}`}
            onClick={() => navigate("/")}
          >
            <Logo />
          </div>
        </div>
        <div className="line-podcast m-0"></div>
        <SideNav.Nav defaultSelected={pagename.toLowerCase()}>
          <NavItem
            onClick={() => handleSelection("Dashboard")}
            eventKey="dashboard"
          >
            <NavIcon>
              <DashboardIcon />
            </NavIcon>
            <NavText>Dashboard</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Content")}
            eventKey="content"
          >
            <NavIcon>
              <PodcastIcon />
            </NavIcon>
            <NavText>Content</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Subscribers")}
            eventKey="subscribers"
          >
            <NavIcon>
              <SubscriberIcon />
            </NavIcon>
            <NavText>Subscribers</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Settings")}
            eventKey="settings"
          >
            <NavIcon>
              <SettingIcon />
            </NavIcon>
            <NavText>Settings</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Help/Support")}
            eventKey="help"
          >
            <NavIcon>
              <HelpIcon />
            </NavIcon>
            <NavText>Help/Support</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Upload Cover Image")}
            eventKey="uploadimage"
          >
            <NavIcon>
              <FaCloudUploadAlt size={20} color="white" />
            </NavIcon>
            <NavText>Upload Image</NavText>
          </NavItem>
          <div className="line-podcast top-logout"></div>
          <div
            className={`logout mt-5 mb-5 cursor ${sidenav ? "" : "d-none"}`}
            onClick={() => setShowDelete(true)}
          >
            <span>Logout</span>
            <LogOutArrow />
          </div>
        </SideNav.Nav>
      </SideNav>
      <ThemeModal
        setState={setShowDelete}
        initialState={showDelete}
        onSubmitModal={onSubmitModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        heading={"Sure? You want to Log Out?"}
        Text={"Click Confirm to log out"}
      />
    </div>
  );
};

export default SideNavBar;
