import React from "react";
import SubscribersTable from "../components/SubscriberTable";
import Assets from "../utils/Assets";

const Subscribers = () => {
  const TableHeader = [
    { id: 1, headerName: "Basic $20/month", headerwidth: "500px" },
    { id: 2, headerName: "Standard $35/month", headerwidth: "400px" },
    { id: 3, headerName: "Premium $55/month", headerwidth: "400px" },
  ];

  const JSONDATA = [];

  for (let i = 1; i <= 50; i++) {
    JSONDATA.push({
      id: i,
      image: Assets.subFirst,
      name: "Jaydon Arcand",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      category: "Reels",
      users: [
        { id: 1, image: Assets.followerFirst },
        { id: 2, image: Assets.followerSecond },
        { id: 3, image: Assets.followerThird },
        { id: 4, image: Assets.followerFourth },
      ],
    });
  }

  return (
    <>
      <div className=" subscribers">
        <SubscribersTable
          TableHeader={TableHeader}
          width={"1152.03px"}
          className="mt-5"
          TableData={JSONDATA}
          maxheight={"866px"}
        />
      </div>
    </>
  );
};

export default Subscribers;
