import React, { forwardRef } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { BsFillCaretDownFill } from "react-icons/bs"; // Import the icon
import Assets from "../utils/Assets";

const CustomSelect = forwardRef((props, ref) => {
  const {
    field,
    label = "",
    placeholder = "",
    options = [],
    required = false,
    value = "",
    onChange,
    disabled = false,
    className = "",
  } = props;

  return (
    <Form.Select
      size="lg"
      {...field}
      placeholder={placeholder}
      className={className}
      value={value?.value || ""}  // Change 1: Ensure the value prop is set correctly
      onChange={(e) => onChange(options.find(opt => opt.value === e.target.value))} // Change 2: Handle onChange event
      // onChange={(value) => onChange(value)}
      disabled={disabled}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
});

export default CustomSelect;
