import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import HeaderBar from "../../components/Dashboard/Header";
import SideNavBar from "../../components/Dashboard/SideBar";
import { useSelector, useDispatch } from "react-redux";

const DahsBoardMenu = ({ children }) => {
  const pagename = useSelector((state) => state.pagename.pagename);
  const sidenav = useSelector((state) => state.sidenav.sidenav);
  const userdetail = useSelector((state) => state.userdetail.userdetail);

  console.log(userdetail, "useremail");
  return (
    <>
      <div className="dashboard-layout w-100">
        <SideNavBar />
        <div className="content w-100">
          <HeaderBar pagename={pagename} />
          <div className="child">{children}</div>
        </div>
      </div>
    </>
  );
};

export default DahsBoardMenu;
