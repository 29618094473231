// src/index.js
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  pagenameReducer,
  searchReducer,
  sidenavReducer,
} from "./Slices/dashBoardSlice";
import persistConfig from "./persistConfig";
import { authApi } from "./Slices/Auth";
import { userdetailReducer, useremailReducer } from "./Slices/UserSlice";
import { videoApi } from "./Slices/Videos";
import { userApi } from "./Slices/users";
import { likeApi } from "./Slices/like";
import { pageApi } from "./Slices/pages";
import { uiCoverImage } from "./Slices/uiimage";

const rootReducer = combineReducers({
  pagename: pagenameReducer,
  sidenav: sidenavReducer,
  useremail: useremailReducer,
  userdetail: userdetailReducer,
  search: searchReducer,
  [authApi.reducerPath]: authApi.reducer,
  [videoApi.reducerPath]: videoApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [likeApi.reducerPath]: likeApi.reducer,
  [pageApi.reducerPath]: pageApi.reducer,
  [uiCoverImage.reducerPath]: uiCoverImage.reducer,
  // Add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
