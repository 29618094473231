import React, { useCallback, useState } from "react";
import UploadVideo from "../components/VideoDetails/UploadVideo";
import { errorToast, success } from "../components/ToastComponent";
import { useDropzone } from "react-dropzone";
import {
  useGetVideoContentBycatQuery,
  useGetVideoContentQuery,
  useUploadVideoMutation,
} from "../Redux/Slices/Videos";
import { useNavigate } from "react-router-dom";

const UploadVideoPage = () => {
  const navigate = useNavigate();
  const [inputStr, setInputStr] = useState("");
  const [uploadvideo, { isLoading, isSuccess, isError, error }] =
    useUploadVideoMutation();
  const fetchAllContents = useGetVideoContentQuery();
  const fetchAllContentsByCat = useGetVideoContentBycatQuery();
  const onDrop = useCallback((acceptedFiles) => {
    const videoMimeTypes = [
      "video/mp4",
      "video/ogg",
      "video/webm",
      "video/avi",
      "video/mov",
    ];
    const file = acceptedFiles[0];

    if (!videoMimeTypes.includes(file.type)) {
      errorToast("Error: The uploaded file is not a video.");
      return;
    }
  }, []);
  const {
    acceptedFiles: acceptedFilesFirst,
    getRootProps: getRootPropsFirst,
    getInputProps: getInputPropsFirst,
  } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const {
    acceptedFiles: acceptedFilesSecond,
    getRootProps: getRootPropsSecond,
    getInputProps: getInputPropsSecond,
  } = useDropzone({
    onDrop,
    accept: "video/*",
  });
  const onDataSubmit = async (data) => {
    // return false;
    if (acceptedFilesFirst?.length > 0 && acceptedFilesSecond?.length > 0) {
      const formData = new FormData();
      formData.append("title", inputStr);
      formData.append("category", data?.category?.value);
      formData.append("plan", data?.package?.value);
      formData.append("visibility", data?.visibility?.value);
      formData.append("description", data.terms);
      formData.append("shortvideo", acceptedFilesFirst[0]);
      formData.append("fullvideo", acceptedFilesSecond[0]);
      try {
        const result = await uploadvideo(formData).unwrap();
        console.log("API Response:", result); // Log API response
        success(result.message);
        fetchAllContents.refetch();
        fetchAllContentsByCat.refetch();
      } catch (e) {
        console.error("API Error:", e); // Log API error
        errorToast(e.data?.message);
      }
    } else {
      errorToast("Video file is Required");
    }
  };

  return (
    <>
      <div className="upload-video-page mt-5 container">
        <UploadVideo
          pageName={"Video Details"}
          btnNAme={"Upload Video"}
          onDataSubmit={onDataSubmit}
          acceptedFilesFirst={acceptedFilesFirst}
          acceptedFilesSecond={acceptedFilesSecond}
          getRootPropsFirst={getRootPropsFirst}
          getInputPropsFirst={getInputPropsFirst}
          getRootPropsSecond={getRootPropsSecond}
          getInputPropsSecond={getInputPropsSecond}
          isLoading={isLoading}
          inputStr={inputStr}
          setInputStr={setInputStr}
          uploadvideo
        />
      </div>
    </>
  );
};
export default UploadVideoPage;
