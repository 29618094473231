import React, { useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { UploadVideoIcon } from "../utils/Svg";
import { errorToast, success } from "./ToastComponent";
import { useUploadUiCoverImageMutation } from "../Redux/Slices/uiimage";

const UploadCoverImage = () => {
  const onDrop = useCallback((acceptedFiles) => {
    const imageMimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];
    const file = acceptedFiles[0];

    if (!imageMimeTypes.includes(file.type)) {
      errorToast("Error: The uploaded file is not an image.");
      return;
    }

    // Process the image file
    console.log("File is an image:", file);
  }, []);

  const {
    acceptedFiles: acceptedFilesFirst,
    getRootProps: getRootPropsFirst,
    getInputProps: getInputPropsFirst,
  } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const {
    acceptedFiles: acceptedFilesSecond,
    getRootProps: getRootPropsSecond,
    getInputProps: getInputPropsSecond,
  } = useDropzone({
    onDrop,
    accept: "video/*",
  });
  const FirstFiles = acceptedFilesFirst?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const SecondFiles = acceptedFilesSecond?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const [uploaduicoverimage, { isLoading, isSuccess, isError, error }] =
    useUploadUiCoverImageMutation();
  const Onsubmit = async (data) => {
    const formData = new FormData();
    formData.append("login_image", acceptedFilesFirst[0]);
    formData.append("signup_image", acceptedFilesSecond[0]);
    try {
      const result = await uploaduicoverimage(formData).unwrap();
      console.log("API Response:", result); // Log API response
      success(result.message);
    } catch (e) {
      console.error("API Error:", e); // Log API error
      errorToast(e.data?.message);
    }
  };

  return (
    <>
      <div className="upload-cover-image container">
        <div className="drag-drop-file mt-5">
          <Row>
            <Col xs={12} md={6}>
              <div className="label-custom mt-3 mb-3">
                <p
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19.2px",
                  }}
                >
                  Login Image
                </p>
                <p
                  style={{
                    color: "#7B818C",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "19.2px",
                  }}
                >
                  {/* Upload a short content video, so the viewers get an idea
                  what’s in the video */}
                </p>
              </div>
              <div className="drag-and-drop d-flex">
                <div {...getRootPropsFirst({ className: "dropzone w-100" })}>
                  <input {...getInputPropsFirst()} />
                  <div className="cursor custom-drag-content ">
                    <UploadVideoIcon />
                    {FirstFiles?.length > 0 ? (
                      <>
                        <h2>uploaded Successfully</h2>
                        <h3>{FirstFiles}</h3>
                      </>
                    ) : (
                      <>
                        <h2>Drag and drop video files to upload</h2>
                        <h3>
                          Your video will be private until you publish them.
                        </h3>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="btn-select w-100"
                  style={{
                    marginRight: "20px",
                  }}
                >
                  <div {...getRootPropsFirst({ className: "btn-dropzone" })}>
                    <input {...getInputPropsFirst()} />
                    <Button>Select Files</Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="label-custom mt-3 mb-3">
                <p
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19.2px",
                  }}
                >
                  Signup Image
                </p>
                <p
                  style={{
                    color: "#7B818C",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "19.2px",
                  }}
                >
                  {/* Upload a complete content video, so when the viewers subscribe
                  or purchase it */}
                </p>
              </div>

              <div className="drag-and-drop d-flex">
                <div {...getRootPropsSecond({ className: "dropzone w-100" })}>
                  <input {...getInputPropsSecond()} />
                  <div className="cursor custom-drag-content">
                    <UploadVideoIcon />
                    {SecondFiles?.length > 0 ? (
                      <>
                        <h2>uploaded Successfully</h2>
                        <h3>{SecondFiles}</h3>
                      </>
                    ) : (
                      <>
                        <h2>Drag and drop video files to upload</h2>
                        <h3>
                          Your video will be private until you publish them.
                        </h3>
                      </>
                    )}
                  </div>
                </div>
                <div className="btn-select  w-100">
                  <div {...getRootPropsSecond({ className: "btn-dropzone" })}>
                    <input {...getInputPropsSecond()} />
                    <Button>Select Files</Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex btn-save mt-5">
              <Button className="" onClick={Onsubmit}>
                Save
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default UploadCoverImage;
