import React from "react"
import { Col, Container, Row } from 'react-bootstrap'
import Assets from "../../utils/Assets"

const AuthLayout = ({ children }) => {
    return (
        <>
            <div className="Authlayout mt-3 container">
                <Row>
                    <Col lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className='d-none d-lg-block' >
                        <div className="left-banner mb-3">
                            <img className='' src={Assets.BannerAuth} alt="" />
                        </div>
                    </Col>

                    <Col lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }} className='d-flex text-align-center'>
                        {children}
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default AuthLayout