import React, { useState } from "react";
import Assets from "../utils/Assets";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "./CustomInput";
import { NavLink, useNavigate } from "react-router-dom/dist";
import { errorToast, success } from "./ToastComponent";
import { useLoginMutation } from "../Redux/Slices/Auth";
import { useDispatch } from "react-redux";
import { setUserDetail } from "../Redux/Slices/UserSlice";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // API
  const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const password = watch("password");

  const handleSignInSubmit = async (data) => {
    try {
      const result = await login(data).unwrap();
      success(result.message);
      dispatch(setUserDetail(result)); // Dispatching the setUserEmail action
      navigate("/dashboard");
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  return (
    <>
      <div className="signin-component">
        <div className="logo mb-4">
          <img src={Assets.Logo} />
        </div>
        <div className="mt-4 mb-1 w-100">
          <h5>Hello There....</h5>
          <h1>Welcome Back</h1>
        </div>
        <div className="sign-in-form">
          <Form onSubmit={handleSubmit(handleSignInSubmit)}>
            <div className="mt-3">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                  },
                  maxLength: {
                    value: VALIDATIONS.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Email Address"
                    placeholder="Email Address"
                    type="email"
                    className="name"
                    required={true}
                    maxLength={VALIDATIONS.EMAIL}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.email && (
                <small className="text-start d-block text-danger">
                  {errors.email.message}
                </small>
              )}
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                }}
                render={({ field }) => (
                  <div className="password-input">
                    <CustomInput
                      {...field}
                      label="Password"
                      placeholder="Password"
                      className="name"
                      type={showPassword ? "text" : "password"}
                      required={true}
                      minLength={VALIDATIONS.PASSWORD_MIN}
                      maxLength={VALIDATIONS.PASSWORD_MAX}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span
                      className="eye-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                )}
              />
              {errors.password && (
                <small className="text-start d-block text-danger">
                  {errors.password.message}
                </small>
              )}
            </div>
            <div className="mt-1 forgot-pass">
              <h3>
                <NavLink to={"/forgot-password"}>Forgot Password</NavLink>
              </h3>
            </div>
            <div className="btn-sumit">
              <Button
                className="btn-solid btn-blue mt-30"
                type="submit"
                disabled={isLoading}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default SignIn;
