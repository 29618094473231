export const constant = {
  DEVICE_TYPE: "web",
  DEVICE_TOKEN: "",
  LOCAL_STORAGE_TOKEN: "foodnauts-token",
  LOCAL_STORAGE_USER: "foodnauts-user",
  LOCAL_STORAGE_ROLE: "foodnauts-role",
  LOCAL_STORAGE_EMAIL_SIGNUP: "signup-email",
  BASE_URL: "http://localhost:5000",
  // BASE_URL_PROD: "http://localhost:3007/api/v1/",
  // BASE_URL_IMAGE: "http://localhost:3007/",
  BASE_URL_PROD: "http://api.tonywilrich.trangotechdevs.com:3006/api/v1/",
  BASE_URL_IMAGE: "http://api.tonywilrich.trangotechdevs.com:3006/",
  IMAGE_DIR_NAME: "images",
  VIDEO_DIR_NAME: "videos",
  VER: "/api",
  auth: "/auth",
};

export const messageTypes = {
  text: 1,
  image: 2,
  video: 3,
  audio: 4,
  doc: 5,
};

export const apiUrl = {
  // Api Urls Here
};

export const s3Credential = {
  bucketName: "",
  region: "",
  accessKeyId: "",
  secretAccessKey: "",
  s3EndPoint: "",
  fileSize: "",
  dirName: "",
};

export const errorMessages = {
  fileSize: "The file size is too large",
  fileSuccess: "file uploaded successfully",
  fileError: "Error in uploading file",
};

export const apiurl = {
  auth: "auth/",
  tonyadmin: "tony-admin/",
  user: "user/",
  signin: "signin",
  forgotPassword: "forgot-password",
  changePassword: "change-password",
  resetPassword: "reset-password",
  verifyOtp: "verify-otp",
  resendOtp: "resend-otp",
  uploadvideo: "upload-video",
  uicoverimage: "upload-ui-image",
  getallvideorecords: "get-all-video-records",
  getvideosbycat: "get-videos-by-cat",
  getvideodetail: "get-video-detail",
  getallusers: "all-users",
  totaluserlikes: "total-user-likes",
  video: "video",
  getmyprofile: "get-my-profile",
  editprofile: "edit-profile",
  changepassword: "change-password",
  page: "page",
};
