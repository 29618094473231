import React from "react";

import {
  ForwardArrow,
  TotalLike,
  TotalSubscribers,
  TotalVideos,
} from "../../utils/Svg";
import { useGetAllusersQuery } from "../../Redux/Slices/users";
import { formatNumber } from "../../utils/Helper";
import { useGetAlllikesQuery } from "../../Redux/Slices/like";
import { GrowLoader } from "../Loader";
import { useGetVideoContentQuery } from "../../Redux/Slices/Videos";

const TopDetails = () => {
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useGetAllusersQuery();
  const {
    data: likesData,
    error: likesError,
    isLoading: likesLoading,
  } = useGetAlllikesQuery();

  const {
    data: videosData,
    error: videosError,
    isLoading: videosLoading,
  } = useGetVideoContentQuery({
    params: {
      page: 1,
    },
  });
  console.log(videosData, "videosData");
  return (
    <>
      <div className="type-details container">
        <div className="box-row mt-4">
          <div className="box">
            <div className="box-content">
              <h2>Total Subscribers</h2>
              <h3 className="mb-3">
                <span>+25%</span> in last 28 days
              </h3>
              <h1>
                {userLoading ? (
                  <GrowLoader />
                ) : (
                  formatNumber(userData?.data?.count)
                )}
              </h1>
            </div>
            <div className="box-content-image">
              <TotalSubscribers />
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Total Likes</h2>
              <h3 className="mb-3">
                <span>+15%</span>in last 28 days
              </h3>
              <h1>
                {likesLoading ? (
                  <GrowLoader />
                ) : (
                  formatNumber(likesData?.data?.count) || 0
                )}
              </h1>
            </div>
            <div className="box-content-image">
              <TotalLike />
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Total Videos</h2>
              <h3 className="mb-3">Last Upload days Ago</h3>
              <h1>
                {" "}
                {videosLoading ? (
                  <GrowLoader />
                ) : (
                  formatNumber(videosData?.data?.totalItems) || 0
                )}
              </h1>
            </div>
            <div className="box-content-image">
              <TotalVideos />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDetails;
