import React from "react";
import { LoaderCustom } from "./Loader";

import { IoCaretForward } from "react-icons/io5";

const Table = ({
  TableHeader,
  TableData,
  header,
  className = "",
  maxheight,
  onSeeAllClick,
  width,
  isLoading,
}) => {
  return (
    <>
      <div
        className={`${className} table-custom container`}
        style={{ width: width }}
      >
        <div className="table-header mb-4">
          {header ? (
            <div className="top-content mb-4 d-flex">
              <h3>Recent Content</h3>
              <span className="ms-auto cursor" onClick={onSeeAllClick}>
                See All
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="column-name d-flex">
            {TableHeader?.map((dt) => (
              <div className="heading-name" style={{ width: dt?.headerwidth }}>
                {dt?.headerName}
              </div>
            ))}
          </div>
          <div className="line-podcast mt-4 mb-1"></div>
        </div>
        {isLoading ? (
          <LoaderCustom className="table-loader" />
        ) : (
          <div
            className="table-content"
            style={{ maxHeight: maxheight, overflowY: "scroll" }}
          >
            {TableData?.length > 0 ? (
              TableData?.map((rowData) => (
                <div className="row-custom mb-4" key={rowData.id}>
                  {TableHeader.map((headerItem) => (
                    <div
                      className="data"
                      style={{ width: headerItem.headerwidth }}
                      key={headerItem.id}
                    >
                      {
                        rowData[
                          headerItem.headerName.toLowerCase().replace(/\s/g, "")
                        ]
                      }
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p className="no-data-found">No Data Found</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
