import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  pagename: "Dashboard",
};

const initialStateSideNav = {
  sidenav: false,
};
const initialStateSearch = {
  search: undefined,
};

export const pagenameSlice = createSlice({
  name: "pagename",
  initialState,
  reducers: {
    setPageName: (state, action) => {
      state.pagename = action.payload;
    },
  },
});
export const sideNavSlice = createSlice({
  name: "sidenav",
  initialState: initialStateSideNav,
  reducers: {
    setSideNav: (state, action) => {
      state.sidenav = action.payload;
    },
  },
});
export const searchSlice = createSlice({
  name: "search",
  initialState: initialStateSearch,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setPageName } = pagenameSlice.actions,
  { setSideNav } = sideNavSlice.actions,
  { setSearch } = searchSlice.actions;
export const pagenameReducer = pagenameSlice.reducer;
export const sidenavReducer = sideNavSlice.reducer;
export const searchReducer = searchSlice.reducer;
